import firebase from "firebase";
import "firebase/firestore";
import firebaseMercanewsConfig from "./firebaseMercanewsConfig";

let apps = firebase.apps.map((e) => e.name).includes("MercaNews")
  ? firebase.apps.find((o) => o.name === "MercaNews")
  : firebase.initializeApp(firebaseMercanewsConfig, "MercaNews");

let mstorage = firebase.storage(apps);

export default mstorage;
