<template>
  <div id="AddNews">
    <v-container>
      <dashboard-core-app-bar />
    </v-container>
    <v-row style="margin-top: 20px">
      <v-breadcrumbs :items="items" divider=">"></v-breadcrumbs>
      <v-col cols="12" md="12">
        <v-card class="pa-3" outlined tile>
          <v-form ref="form">
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    label="Titre"
                    v-model="titre"
                    prepend-icon="mdi-pen"
                    :rules="rule"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-file-input
                    :rules="rule"
                    accept="image/png, image/jpeg, image/bmp"
                    prepend-icon="mdi-camera"
                    label="Image"
                    v-model="image"
                  ></v-file-input>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete
                    :items="listeCategory"
                    item-value="collection"
                    item-text="category"
                    label="Catégorie"
                    v-model="collection"
                    prepend-icon="mdi-list-box"
                    clearable
                    hide-details
                    :rules="rule"
                    hide-selected
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete
                    :items="listeLanguage"
                    clearable
                    hide-details
                    hide-selected
                    label="Language"
                    :rules="rule"
                    item-value="language"
                    prepend-icon="mdi-list-box"
                    v-model="language"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-textarea
                    name="input-7-4"
                    no-resize
                    label="Description"
                    v-model="description"
                    prepend-icon="mdi-text-box"
                    :rules="rule"
                  ></v-textarea>
                </v-col>
              </v-row>
              <div class="d-flex flex-row-reverse">
                <v-btn
                  :disabled="!valid"
                  color="success"
                  @click="validate"
                  class="mr-4"
                  style="width: 90px"
                  >Ajouter</v-btn
                >

                <v-btn
                  color="error"
                  class="mr-4"
                  @click="reset"
                  style="width: 90px"
                >
                  Réinitialiser
                </v-btn>
              </div>
            </v-container>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" max-width="330">
      <v-card>
        <v-card-title class="text-h5">
          Etes vous sur de vouloir ajouter l'actualité ?
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="dialog = false">
            Annuler
          </v-btn>

          <v-btn color="green darken-1" :loading="loading" text @click="Submit">
            Confirmer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="reussi" max-width="390">
      <v-card>
        <v-card-title class="text-h3">
          L'actualité a bien été ajouté !
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" text @click="$router.push('ListeDesFlux')">
            Liste des flux
          </v-btn>
          <v-btn color="primary" text @click="reussi = false">
            Rester sur la page
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import NodesName from "../../utils/nodesName";
import db from "../../../mercanewsFirebaseInit";
import uniqid from "uniqid";
import Function from "../../utils/functions";
import mstorage from "../../../mercanewsStorageInit";

export default {
  name: "AddNews",
  components: {
    DashboardCoreAppBar: () =>
      import("../../materialDashboard/components/core/AppBar.vue"),
  },
  data() {
    return {
      items: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/",
        },
      ],
      titre: "",
      description: "",
      language: "",
      image: [],
      category: "",
      collection: "",

      rule: [(v) => !!v || "Ce champ est requis"],
      listeCategory: [],
      listeLanguage: [
        {
          text: "AR",
        },
        {
          text: "FR",
        },
      ],
      dialog: false,
      valid: true,
      reussi: false,
      categorieList: new Map(),
      loading: false,
    };
  },
  methods: {
    getCategorieActualite() {
      db.collection(NodesName.CATEGORIES)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.categorieList.set(doc.data().collection, doc.data().category);
            this.listeCategory.push({
              collection: doc.data().collection,
              category: doc.data().category,
            });
          });
        });
    },
    reset() {
      this.$refs.form.reset();
    },

    Submit() {
      var linkId = uniqid();
      this.loading = true;
      mstorage
        .ref(
          "images_a_publier/" +
            this.categorieList.get(this.collection) +
            "/Mercanews/" +
            linkId
        )
        .put(this.image)
        .then(() => {
          mstorage
            .ref(
              "images_a_publier/" +
                this.categorieList.get(this.collection) +
                "/Mercanews/" +
                linkId
            )
            .getDownloadURL()
            .then((url) => {
              const actualite = {
                title: this.titre,
                description: this.description,
                image: url,
                language: this.language,
                category: this.collection,
                source: "https://mercanews.mercarue.com/",
                sourceName: "Mercanews",
                archived: "false",
                date: Function.adjustForTimezone(new Date()).toLocaleDateString(
                  "fr-US",
                  {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  }
                ),
                link: "https://mercanews.mercarue.com/detailsFeed/" + linkId,
                notifieted: false,
                publish: false,
                selected: false,
                nbrConsulted: 0,
                nbrLike: 0,
                libelle: this.categorieList.get(this.collection),
                uniqueId: linkId,
                isoDate: new Date(),
              };

              db.collection(NodesName.RSS_FEED)
                .add(actualite)
                .then(() => {
                  this.dialog = false;
                  this.reussi = true;
                });
            });
        });
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.dialog = true;
      }
    },
  },
  created() {
    this.getCategorieActualite();
  },
};
</script>
